import { ReactNode, memo } from 'react';
import { HeaderMemo, HeaderProps } from '../header/header';
import { Footer } from '../footer/footer';
import { PlayerQuestionnaire } from '../player-questionnaire/player-questionnaire';
import { useStore } from '../../providers/store-provider';
import { useRootClass } from '../../hooks/use-root-class';
import styles from './page.module.css';

export interface PageProps {
  children: ReactNode;
  headerProps?: HeaderProps;
}

export function Page({ children, headerProps }: PageProps) {
  const hasRecentlyPlayedGames = useStore(({ recentlyPlayed }) => !!recentlyPlayed.length);

  // Toggle "hasRecentlyPlayedGames" class on the document element based on
  // whether there are recently played games or not.
  useRootClass('hasRecentlyPlayedGames', hasRecentlyPlayedGames);

  return (
    <>
      <HeaderMemo {...headerProps} />
      <PlayerQuestionnaire />
      <main className={styles.content}>{children}</main>
      <Footer />
    </>
  );
}

export const PageMemo = memo(Page);

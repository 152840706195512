import { useRef, memo, ReactNode } from 'react';
import { useElementSize } from '../../hooks/use-element-size';
import { RayLogoIcon } from '../svg-icons/ray-logo-icon';
import { NoiseBackground } from '../noise-background/noise-background';
import {
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_DISABLED_ATTRIBUTE,
} from '@raybrowser/nav-system';
import styles from './header.module.css';

function computeHeaderClipPath(
  containerSize: ReturnType<typeof useElementSize>,
  inverseBorderRadius = 12,
) {
  const { width: w, height: h } = containerSize;
  const b = inverseBorderRadius;
  return [
    'M 0 0',
    `H ${w}`,
    `V ${h}`,
    `Q ${w} ${h - b}, ${w - b} ${h - b}`,
    `H ${b}`,
    `Q 0 ${h - b}, 0 ${h}`,
    `V 0`,
    `Z`,
  ].join(' ');
}

export interface HeaderProps {
  childrenLeft?: ReactNode;
  childrenRight?: ReactNode;
}

export function Header({ childrenLeft, childrenRight }: HeaderProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentSize = useElementSize(contentRef);

  return (
    <header
      className={`${styles.root} ${ARROW_NAV_CONTAINER_CLASS}`}
      {...{
        [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
        [ARROW_NAV_IGNORE_ATTRIBUTE]: 'y',
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: '',
        [FOCUS_SCROLL_DISABLED_ATTRIBUTE]: '',
      }}
    >
      <div
        ref={contentRef}
        className={styles.content}
        style={{
          clipPath: `path('${computeHeaderClipPath(contentSize)}')`,
        }}
      >
        <NoiseBackground />
        <div className={styles.leftBlock}>{childrenLeft}</div>
        <RayLogoIcon className={styles.logo} />
        <div className={styles.rightBlock}>{childrenRight}</div>
      </div>
      <div className={styles.contentBorder}></div>
    </header>
  );
}

export const HeaderMemo = memo(Header);

import { forwardRef, memo, ComponentProps } from 'react';
import styles from './noise-background.module.css';

export interface NoiseBackgroundProps extends Omit<ComponentProps<'div'>, 'ref'> {}

export const NoiseBackground = forwardRef<HTMLDivElement, NoiseBackgroundProps>(
  function NoiseBackground({ className = '', ...props }, ref) {
    return <div ref={ref} className={`${styles.root} ${className}`} {...props}></div>;
  },
);

export const NoiseBackgroundMemo = memo(NoiseBackground);

import { memo, useState, useRef } from 'react';
import Image from 'next/image';
import { Shimmer } from '../shimmer/shimmer';
import { Button } from '../buttons/button/button';
import { SpeakerOffIcon } from '../svg-icons/speaker-off-icon';
import { SpeakerOnIcon } from '../svg-icons/speaker-on-icon';
import { PlayIcon } from '../svg-icons/play-icon';
import { PauseIcon } from '../svg-icons/pause-icon';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';
import { useIsPageVisible } from '../../hooks/use-is-page-visible';
import { useStore } from '../../providers/store-provider';
import { ARROW_NAV_TARGET_CLASS } from '@raybrowser/nav-system';
import styles from './video-player.module.css';

export type VideoPlayerProps = {
  videoSrc?: string;
  posterSrc?: string;
  posterAlt?: string;
  posterSizes?: string;
  posterPriority?: boolean;
  posterQuality?: number;
  className?: string;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
};

export function VideoPlayer({
  videoSrc = '',
  posterSrc = '',
  posterAlt = '',
  posterSizes = '100vw',
  posterPriority = false,
  posterQuality = 85,
  className = '',
  onPlay,
  onPause,
  onEnded,
}: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const isPageVisible = useIsPageVisible();
  const isMediaMuted = useStore(({ isMediaMuted }) => isMediaMuted);
  const debugDisableVideos = useStore(({ debug }) => debug.disableVideos);
  const setMediaMuted = useStore(({ setMediaMuted }) => setMediaMuted);

  // Reset image loaded state when poster changes.
  useIsomorphicLayoutEffect(() => {
    setImageLoaded(false);
  }, [posterSrc, setImageLoaded]);

  // Play/pause video.
  useIsomorphicLayoutEffect(() => {
    if (!videoSrc || !isVideoPlaying || debugDisableVideos !== false) return;

    const video = videoRef.current;
    if (!video) return;

    let didUnmount = false;

    video.play().catch((e) => {
      if (didUnmount) return;
      console.error(e);
    });

    return () => {
      didUnmount = true;
      if (!video.paused) {
        video.pause();
      }
    };
  }, [videoSrc, debugDisableVideos, isVideoPlaying]);

  // Pause video on esc.
  useIsomorphicLayoutEffect(() => {
    if (!isVideoPlaying) return;

    const pauseOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        setVideoPlaying(false);
      }
    };

    document.addEventListener('keydown', pauseOnEsc);

    return () => {
      document.removeEventListener('keydown', pauseOnEsc);
    };
  }, [isVideoPlaying, setVideoPlaying]);

  return (
    <div
      className={`${styles.root} ${className} ${isVideoPlaying ? styles.isPlaying : ''}`}
      data-playing={isVideoPlaying ? 'true' : 'false'}
    >
      <Shimmer visible={!isVideoPlaying && !isImageLoaded} />
      <Image
        className={styles.poster}
        src={posterSrc}
        alt={posterAlt}
        priority={posterPriority}
        sizes={posterSizes}
        quality={posterQuality}
        draggable="false"
        aria-hidden="true"
        fill
        onLoad={() => setImageLoaded(true)}
      />
      {!!videoSrc && !debugDisableVideos && (
        <div
          className={styles.videoContainer}
          onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains(styles.videoContainer || '')) {
              setVideoPlaying(!isVideoPlaying);
            }
          }}
        >
          <video
            ref={videoRef}
            src={videoSrc}
            muted={isPageVisible ? isMediaMuted : true}
            aria-hidden="true"
            preload="none"
            onPlay={onPlay}
            onPause={onPause}
            onEnded={() => {
              onEnded?.();
              setVideoPlaying(false);
            }}
          ></video>
          <div className={styles.toggleVideo}>
            <Button
              className={ARROW_NAV_TARGET_CLASS}
              theme="SecondaryDark"
              aria-label={isVideoPlaying ? 'pause video' : 'play video'}
              onClick={() => setVideoPlaying(!isVideoPlaying)}
            >
              {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
            </Button>
          </div>
          <div className={styles.muteVideo}>
            <Button
              className={ARROW_NAV_TARGET_CLASS}
              theme="SecondaryDark"
              tabIndex={isVideoPlaying ? 0 : -1}
              disabled={!isVideoPlaying}
              aria-label={isVideoPlaying ? 'unmute video' : 'mute video'}
              onClick={() => setMediaMuted(!isMediaMuted)}
            >
              {isMediaMuted ? <SpeakerOffIcon /> : <SpeakerOnIcon />}
            </Button>
          </div>
        </div>
      )}
      <div className={styles.highlight}></div>
    </div>
  );
}

export const VideoPlayerMemo = memo(VideoPlayer);

import { memo, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { AppCardPopoverVideoMemo } from './app-card-popover-video';
import { AnchorButton } from '../buttons/button/button';
import { AppDetailLink } from '../app-detail-link/app-detail-link';
import { Tag } from '../tag/tag';
import { ChevronDownIcon } from '../svg-icons/chevron-down-icon';
import { useStore } from '../../providers/store-provider';
import { navSystem } from '@raybrowser/nav-system';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';
import styles from './app-card-popover.module.css';

export type AppCardPopoverProps = {
  // App data
  id: string;
  name: string;
  launchUrl: string;
  previewImageUrl: string;
  previewVideoUrl?: string;
  shortDescription?: string;
  tags?: { id: string; name: string }[];
  // Other props
  isActive: boolean;
  isHiding: boolean;
};

export const AppCardPopover = ({ isActive, isHiding, ...app }: AppCardPopoverProps) => {
  const launchActionRef = useRef<HTMLAnchorElement | null>(null);
  const videoCurrentTimeRef = useRef<number>(0);
  const addRecentlyPlayed = useStore(({ addRecentlyPlayed }) => addRecentlyPlayed);
  const [isVisible, setVisible] = useState(false);

  // Focus the launch action when the popover is opened.
  useIsomorphicLayoutEffect(() => {
    if (
      isActive &&
      launchActionRef.current &&
      launchActionRef.current !== document.activeElement &&
      navSystem.getNavDevice() === 'keyboard'
    ) {
      launchActionRef.current.focus({ preventScroll: true });
    }
  }, [isActive]);

  // We use the visible state to animate the popover in so we need to wait for
  // the popover to be rendered first before we apply the visible class.
  useEffect(() => {
    setVisible(isActive);
  }, [isActive]);

  return isActive || isHiding ? (
    <div className={`${styles.root} ${isVisible ? styles.isVisible : ''}`}>
      <div className={styles.container}>
        <div className={styles.bg}> </div>
        <div className={styles.imageContainer}>
          <Image
            className={styles.image}
            src={app.previewImageUrl}
            alt={app.name}
            draggable="false"
            fill
            quality={85}
            sizes={`(max-width: 640px) 50vw,(max-width: 1280px) 33vw,(max-width: 1920px) 25vw,(max-width: 2560px) 20vw,(max-width: 3200px) 16vw,(max-width: 3840px) 14vw`}
          />
          {!!app.previewVideoUrl && (
            <AppCardPopoverVideoMemo
              videoUrl={app.previewVideoUrl}
              currentTimeRef={videoCurrentTimeRef}
              isActive={isActive}
            />
          )}
          <a
            className={styles.imageLink}
            href={app.launchUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => addRecentlyPlayed(app.id)}
            tabIndex={-1}
            draggable={false}
          ></a>
        </div>
        <div className={styles.title}>{app.name}</div>
        {!!app.shortDescription && <div className={styles.description}>{app.shortDescription}</div>}
        {!!app.tags?.length && (
          <div className={styles.tags}>
            {app.tags.map((tag) => (
              <Tag key={tag.id} name={tag.name} />
            ))}
          </div>
        )}
        <div className={styles.actions}>
          <AnchorButton
            ref={launchActionRef}
            className={styles.launchAction}
            theme="Primary"
            href={app.launchUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => addRecentlyPlayed(app.id)}
          >
            Play
          </AnchorButton>
          <AppDetailLink
            className={styles.infoAction}
            theme="SecondaryLight"
            href={`?app=${app.id}`}
          >
            <ChevronDownIcon />
          </AppDetailLink>
        </div>
      </div>
    </div>
  ) : null;
};

export const AppCardPopoverMemo = memo(AppCardPopover);

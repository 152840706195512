import { useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { GetIndexPage, GetRecentlyPlayed } from '../graphql-queries';
import { PageMemo } from '../components/page/page';
import { SliderMemo } from '../components/slider/slider';
import { FeaturedTagsMemo } from '../components/featured-tags/featured-tags';
import { initApollo, addToApolloState } from '../utils/apollo-client';
import { AppModal } from '../components/app-modal/app-modal';
import { useStore } from '../providers/store-provider';
import { useApolloClient } from '@apollo/client';
import type { GetIndexPageQuery } from '../.gql/graphql';
import type { SlideData } from '../components/slider/types';

function useSlides(data: GetIndexPageQuery) {
  const apolloClient = useApolloClient();
  const featuredSlide: SlideData | undefined = useMemo(
    () =>
      (data.featuredApps
        ?.filter(({ app }) => !!app)
        .map((data) => {
          const app = data.app!;
          return {
            id: app.id,
            name: app.name || '',
            shortDescription: app.shortDescription || '',
            launchUrl: app.launchUrl || '',
            infoUrl: `?app=${app.id}`,
            bgImageUrl: app.bgImage?.url || '',
            bgVideoUrl: app.bgVideo?.url || '',
            logoImageUrl: app.logoImage?.url || '',
            previewImageUrl: app.previewImage?.url || '',
          };
        }) || [])[0],
    [data],
  );
  const [recentlyPlayedSlides, setRecentlyPlayedSlides] = useState<SlideData[]>([]);
  const recentlyPlayedIds = useStore(({ recentlyPlayed }) => recentlyPlayed);
  const loadRecentlyPlayed = useStore(({ loadRecentlyPlayed }) => loadRecentlyPlayed);

  // Load recently played ids from local storage on mount.
  useEffect(() => {
    if (typeof window === 'undefined') return;
    loadRecentlyPlayed();
  }, [loadRecentlyPlayed]);

  // Whenever the list of recently played ids changes, update the recently
  // played data.
  useEffect(
    () => {
      if (typeof window === 'undefined') return;

      if (!recentlyPlayedIds.length) {
        setRecentlyPlayedSlides([]);
        return;
      }

      let didUnmount = false;

      apolloClient
        .query({
          query: GetRecentlyPlayed,
          variables: { appIds: recentlyPlayedIds },
        })
        .then(({ data }) => {
          if (didUnmount) return;

          const slides =
            data.apps?.map((app) => {
              return {
                id: app.id,
                name: app.name || '',
                shortDescription: app.shortDescription || '',
                launchUrl: app.launchUrl || '',
                infoUrl: `?app=${app.id}`,
                bgImageUrl: app.bgImage?.url || '',
                bgVideoUrl: app.bgVideo?.url || '',
                logoImageUrl: app.logoImage?.url || '',
                previewImageUrl: app.previewImage?.url || '',
              };
            }) || [];

          const slidesOrdered = recentlyPlayedIds
            .map((id) => slides.find((app) => app.id === id))
            .filter(Boolean) as SlideData[];

          setRecentlyPlayedSlides(slidesOrdered);
        })
        .catch((e) => {
          if (didUnmount) return;
          // TODO: Handle error properly (e.g. send to Sentry).
          console.error(`Failed to prefetch recently played data for app: ${recentlyPlayedIds}`);
          console.error(e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient, recentlyPlayedIds],
  );

  return useMemo(
    () => {
      const slides = [];
      if (featuredSlide) {
        slides.push(featuredSlide);
        if (recentlyPlayedSlides.length > 0) {
          slides.push(...recentlyPlayedSlides);
        }
      }
      return slides;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featuredSlide?.id, recentlyPlayedSlides],
  );
}

function useTags(data: GetIndexPageQuery) {
  return useMemo(
    () =>
      data.featuredTags
        ?.filter(({ tag }) => !!tag)
        .map((data) => {
          const tag = data.tag!;
          return {
            id: tag.id,
            name: tag.name || '',
            apps:
              tag.featuredApps
                ?.filter(({ app }) => !!app)
                .map((data) => {
                  const app = data.app!;
                  return {
                    id: app.id,
                    name: app.name || '',
                    shortDescription: app.shortDescription || '',
                    launchUrl: app.launchUrl || '',
                    logoImageUrl: app.logoImage?.url || '',
                    previewImageUrl: app.previewImage?.url || '',
                    previewVideoUrl: app.previewVideo?.url || '',
                    tags:
                      app.tags?.map((tag) => {
                        return {
                          id: tag.id,
                          name: tag.name || '',
                        };
                      }) || [],
                  };
                }) || [],
          };
        }) || [],
    [data],
  );
}

export default function IndexPage({ data }: { data: GetIndexPageQuery }) {
  const slides = useSlides(data);
  const tags = useTags(data);

  return (
    <>
      <Head>
        <title key="title">Ray Portal</title>
        <meta key="description" name="description" content="Discover the best web games." />
      </Head>
      <PageMemo>
        <SliderMemo slides={slides} />
        <FeaturedTagsMemo tags={tags} />
        <AppModal />
      </PageMemo>
    </>
  );
}

export async function getStaticProps() {
  const apolloClient = initApollo();
  const { data } = await apolloClient.query({
    query: GetIndexPage,
  });

  return addToApolloState(apolloClient, {
    props: { data },
  });
}

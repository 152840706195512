import { RefObject, useState } from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

export function useElementSize(ref: RefObject<HTMLElement>) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useIsomorphicLayoutEffect(() => {
    const elem = ref.current;
    if (!elem) return;

    // Compute initial element size.
    const { width, height } = elem.getBoundingClientRect();
    setSize({ width, height });

    // Recompute size on element resize.
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (!entry) return;

      const bbsize = entry.borderBoxSize[0];
      if (!bbsize) return;

      setSize({ width: bbsize.inlineSize, height: bbsize.blockSize });
    });
    resizeObserver.observe(elem);

    return () => {
      resizeObserver.disconnect();
    };
  }, [setSize]);

  return size;
}

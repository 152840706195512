import { memo, useCallback, useRef, useState, useEffect } from 'react';
import Image from 'next/image';
import { useSymbol } from '../../hooks/use-symbol';
import { useStore } from '../../providers/store-provider';
import {
  setCardDeactivationTimeout,
  clearCardDeactivationTimeout,
} from '../../utils/card-deactivation-timeout';
import {
  navSystem,
  ARROW_NAV_TARGET_CLASS,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '@raybrowser/nav-system';
import { AppCardPopoverMemo } from './app-card-popover';
import styles from './app-card.module.css';

const HIDE_ANIMATION_DURATION = 200;

export type AppCardProps = {
  id: string;
  name: string;
  launchUrl: string;
  previewImageUrl: string;
  previewVideoUrl?: string;
  shortDescription?: string;
  tags?: { id: string; name: string }[];
};

export function AppCard(props: AppCardProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const cardId = useSymbol();
  const isActive = useStore(({ activeCardId }) => activeCardId === cardId);
  const [isPopoverVisible, setPopoverVisible] = useState(isActive);
  const isPopoverHiding = isPopoverVisible && !isActive;
  const setActiveCardId = useStore(({ setActiveCardId }) => setActiveCardId);

  const activateCard = useCallback(() => {
    clearCardDeactivationTimeout();
    if (!isActive) {
      setActiveCardId(cardId);
    }
  }, [cardId, isActive, setActiveCardId]);

  const deactivateCard = useCallback(() => {
    setCardDeactivationTimeout(() => {
      setActiveCardId(null);
    });
  }, [setActiveCardId]);

  const onPointerEnter = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      activateCard();
    }
  }, [activateCard]);

  const onPointerLeave = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      deactivateCard();
    }
  }, [deactivateCard]);

  const onFocus = useCallback(() => {
    activateCard();
  }, [activateCard]);

  const onBlur = useCallback(() => {
    deactivateCard();
  }, [deactivateCard]);

  // Track the active state of the card and show the popover when it becomes
  // active. On hide, let's wait for the duration of the popover's hide
  // animation before setting the isPopoverVisible state to false.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (isActive) {
      setPopoverVisible(true);
      return;
    }

    let timeout = window.setTimeout(() => {
      setPopoverVisible(false);
    }, HIDE_ANIMATION_DURATION);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isActive]);

  return (
    <div
      ref={ref}
      className={`${styles.root} ${isActive ? styles.isActive : ''} ${isPopoverHiding ? styles.isHiding : ''}`}
      onPointerDown={onPointerEnter}
      onPointerUp={onPointerEnter}
      onPointerMove={onPointerEnter}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      data-card-popover-visible={isPopoverVisible ? 'true' : 'false'}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: '',
      }}
    >
      <div
        className={styles.scrollToTarget}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: '',
        }}
      ></div>
      <div
        className={`${styles.focusTarget} ${ARROW_NAV_TARGET_CLASS}`}
        tabIndex={isActive ? -1 : 0}
      ></div>
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={props.previewImageUrl}
          alt={props.name}
          draggable="false"
          fill
          quality={85}
          sizes={`(max-width: 640px) 50vw,(max-width: 1280px) 33vw,(max-width: 1920px) 25vw,(max-width: 2560px) 20vw,(max-width: 3200px) 16vw,(max-width: 3840px) 14vw`}
        />
      </div>
      <div className={styles.title}>{props.name}</div>
      <AppCardPopoverMemo isActive={isActive} isHiding={isPopoverHiding} {...props} />
    </div>
  );
}

export const AppCardMemo = memo(AppCard);

import { useCallback, useEffect, useRef, useState, useMemo, PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client';
import FocusTrap from 'focus-trap-react';
import { GetAppModal } from '../../graphql-queries';
import { AnchorButton, Button } from '../buttons/button/button';
import { Modal } from '../modal/modal';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { MarkdownMemo } from '../markdown/markdown';
import { VideoPlayer } from '../video-player/video-player';
import { Tag } from '../tag/tag';
import { CloseIcon } from '../svg-icons/close-icon';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';
import { useStore } from '../../providers/store-provider';
import {
  navSystem,
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_TARGET_GROUP_CLASS,
  ARROW_NAV_STICKY_CLASS,
  ARROW_NAV_CONTAINER_CLASS,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_DISABLED_ATTRIBUTE,
  FOCUS_SCROLL_PADDING_ATTRIBUTE,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
} from '@raybrowser/nav-system';
import type { Options as FocusTrapOptions } from 'focus-trap';
import type { GetAppModalQuery } from '../../.gql/graphql';
import styles from './app-modal.module.css';

const MODAL_CLOSE_TIMEOUT = 200;

export function AppModal() {
  const router = useRouter();
  const nextAppId = typeof router.query.app === 'string' ? router.query.app : '';
  const [currentAppId, setCurrentAppId] = useState('');
  const handleClose = useCallback(() => {
    router.push(router.pathname, undefined, { scroll: false, shallow: true });
  }, [router]);

  // Sync current app id with next app id.
  useEffect(() => {
    // Nothing to do if we are not in the browser.
    if (typeof window === 'undefined') return;

    // Nothing to do if next app id is the same as the current app id.
    if (nextAppId === currentAppId) return;

    // If we have a current app id we need to give time for the modal to close
    // before we can open the next one.
    if (currentAppId) {
      const timeout = window.setTimeout(() => {
        setCurrentAppId(nextAppId);
      }, MODAL_CLOSE_TIMEOUT);
      return () => {
        window.clearTimeout(timeout);
      };
    }

    // In other cases, let's just sync the current app id with the next app id
    // and be done with it.
    setCurrentAppId(nextAppId);
    return;
  }, [nextAppId, currentAppId, setCurrentAppId]);

  return (
    <Modal open={!!nextAppId && !!currentAppId} onClose={handleClose}>
      {!!currentAppId && (
        <AppModalContainer
          id={currentAppId}
          open={currentAppId === nextAppId}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
}

function AppModalContainer({
  id,
  open,
  onClose,
}: {
  id: string;
  open: boolean;
  onClose: () => void;
}) {
  const { data, loading, error } = useQuery(GetAppModal, { variables: { appId: id } });
  const appData = data?.app;

  return (
    <div className={styles.root}>
      {id && loading ? (
        <div className={styles.loader}>
          <LoadingSpinner active={open} />
        </div>
      ) : id && error ? (
        <AppModalErrorPanel open={open} onClose={onClose} />
      ) : appData ? (
        <AppModalContentPanel appData={appData} open={open} onClose={onClose} />
      ) : null}
    </div>
  );
}

function AppModalErrorPanel({ open, onClose }: { open: boolean; onClose: () => void }) {
  const [isPanelOpen, setPanelOpen] = useState(false);

  // Delay open state change to allow for the panel to animate in.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!open) {
      setPanelOpen(false);
      return;
    }

    let rafId = window.requestAnimationFrame(() => {
      rafId = window.requestAnimationFrame(() => {
        setPanelOpen(true);
      });
    });

    return () => {
      window.cancelAnimationFrame(rafId);
    };
  }, [setPanelOpen, open]);

  return (
    <AppModalFocusTrap>
      <div
        className={`${styles.panel} ${styles.errorPanel} ${isPanelOpen ? styles.isPanelOpen : ''}`}
      >
        <p>
          Oh no, something went terribly wrong. We failed to fetch the data for some reason. Please
          make sure your internet connection is working normally and try again.
        </p>
        <Button
          className={`${styles.closeButton} ${ARROW_NAV_TARGET_CLASS}`}
          theme="SecondaryLight"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </div>
    </AppModalFocusTrap>
  );
}

function AppModalContentPanel({
  appData,
  open,
  onClose,
}: {
  appData: GetAppModalQuery['app'];
  open: boolean;
  onClose: () => void;
}) {
  const panelRef = useRef<HTMLDivElement>(null);
  const panelContentRef = useRef<HTMLDivElement>(null);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const addRecentlyPlayed = useStore(({ addRecentlyPlayed }) => addRecentlyPlayed);

  // Delay open state change to allow for the panel to animate in.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!open) {
      setPanelOpen(false);
      return;
    }

    let rafId = window.requestAnimationFrame(() => {
      rafId = window.requestAnimationFrame(() => {
        setPanelOpen(true);
      });
    });

    return () => {
      window.cancelAnimationFrame(rafId);
    };
  }, [setPanelOpen, open]);

  // Track panel content scrollbar width.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    const panel = panelRef.current;
    const panelContent = panelContentRef.current;
    if (!panel || !panelContent) return;

    const computeScrollbarWidth = () => {
      const scrollbarWidth = panelContent.offsetWidth - panelContent.clientWidth;
      panel.style.setProperty('--app-modal--scrollbar-width', `${scrollbarWidth}px`);
    };

    computeScrollbarWidth();
    window.addEventListener('resize', computeScrollbarWidth);

    return () => {
      window.removeEventListener('resize', computeScrollbarWidth);
    };
  }, [appData]);

  if (!appData) return null;

  return (
    <AppModalFocusTrap>
      <div
        ref={panelRef}
        className={`${styles.panel} ${styles.contentPanel} ${isPanelOpen ? styles.isPanelOpen : ''}`}
      >
        <Button
          className={`${styles.closeButton} ${ARROW_NAV_TARGET_CLASS}`}
          theme="SecondaryDark"
          onClick={onClose}
          {...{
            [FOCUS_SCROLL_DISABLED_ATTRIBUTE]: '',
          }}
        >
          <CloseIcon />
        </Button>
        <div ref={panelContentRef} className={`${styles.content} ${ARROW_NAV_CONTAINER_CLASS}`}>
          <div
            className={`${styles.videoPlayerContainer} ${ARROW_NAV_CONTAINER_CLASS} ${ARROW_NAV_TARGET_GROUP_CLASS}`}
            {...{
              [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
              [ARROW_NAV_IGNORE_ATTRIBUTE]: 'y',
              [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: '',
              [FOCUS_SCROLL_PADDING_ATTRIBUTE]: '0',
            }}
          >
            <VideoPlayer
              className={styles.videoPlayer}
              videoSrc={appData.previewVideo?.url}
              posterSrc={appData.previewImage?.url}
              posterAlt={appData.name || ''}
              posterPriority={true}
              posterSizes="(max-width: 1090px) 100vw, 950px"
            />
          </div>
          <div className={styles.info}>
            <div className={styles.infoRight}>
              <div className={styles.infoRightSticky}>
                <AnchorButton
                  className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
                  theme="Primary"
                  href={appData.launchUrl || ''}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => addRecentlyPlayed(appData.id)}
                  {...{
                    [FOCUS_SCROLL_PADDING_ATTRIBUTE]: '32 0',
                  }}
                >
                  Play
                </AnchorButton>
              </div>
            </div>
            <div
              className={`${styles.infoLeft} ${ARROW_NAV_TARGET_CLASS} ${ARROW_NAV_STICKY_CLASS}`}
              tabIndex={0}
              aria-label="info"
              {...{
                [FOCUS_SCROLL_PADDING_ATTRIBUTE]: '32 0',
              }}
            >
              <h2 className={styles.title}>{appData.name}</h2>
              {!!appData.description && (
                <MarkdownMemo className={styles.description}>{appData.description}</MarkdownMemo>
              )}
              {!!appData.tags?.length && (
                <div className={styles.tags}>
                  {appData.tags.map((tag) => (
                    <Tag {...tag} key={tag.id} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppModalFocusTrap>
  );
}

function AppModalFocusTrap({ children }: PropsWithChildren<{}>) {
  const options = useMemo<FocusTrapOptions>(() => {
    return {
      initialFocus: () => (navSystem.getNavDevice() === 'keyboard' ? undefined : false),
      allowOutsideClick: true,
      returnFocusOnDeactivate: true,
      preventScroll: false,
      escapeDeactivates: false,
      isKeyForward: (e) => {
        if (e.key === 'Tab' && !e.shiftKey) {
          if (navSystem.getPreviousNavDevice() === 'keyboard') {
            return true;
          }
        }
        return false;
      },
      isKeyBackward: (e) => {
        if (e.key === 'Tab' && e.shiftKey) {
          if (navSystem.getPreviousNavDevice() === 'keyboard') {
            return true;
          }
        }
        return false;
      },
    };
  }, []);

  return <FocusTrap focusTrapOptions={options}>{children}</FocusTrap>;
}
